import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('sale_societies', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`sale_close/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('sale_close', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        requisition: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('notes', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.put(`sale_close/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        sale: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.put(`sales/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`sale_close/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        seasons: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('seasons', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        buyers: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('buyers', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        societies: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('societies', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        markets: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('markets', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        sales: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('sales', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        grades: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('grades', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        growers: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('growers', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        locations: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('locations', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        registrationNumbers: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('registration_numbers', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    }
}
